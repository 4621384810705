import React from "react";
import "../nico.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";

function ArtisanHorizontal(props) {
  const { name, imageUrl } = props; // nome, bio, immagine e immagini dei lavori
  const bio = (
    <div>
    <p>Nato a Torino nel 1981 sotto il segno dell’amore e dell’arte (Bilancia), appassionato fin dall’infanzia di ballo, canto e disegno, doni che ha ereditato dal padre pittore, batterista e appassionato dei Beatles.</p>

<p>Fin da bimbo si è dimostrato solare ed estroverso, un piccolo showman pronto a stupire chiunque gli fosse intorno. Gia all’asilo ha mostrato una spiccata capacità nell’illustrazione, e cresciuto ha sviluppato un amore per l’arte grafica giapponese (manga), creando diverse strip comics e fumetti che raccontavano fatti accaduti nella sua vita.</p>

<p>Dopo essersi diplomato in grafica pubblicitaria, ha sviluppato una grande passione per la creazione grafica di libri fotografici (cantanti che ama, telefilm o semplicemente foto di vacanze), editing video (concerti, progetti teatrali, matrimoni), cover per CD, DVD e Blu-ray per una sua collezione personale di film e progetti musicali fuori commercio.</p>

<p>Sul fronte musicale, è attualmente il frontman del progetto “THE SOUND OF MOTOWN” e del gruppo “<a href="https://www.facebook.com/AlexandtheMotownBand/" target="_blank">Alex and The Motown band</a>”, con cui porta in scena una scaletta di 30 canzoni che spaziano dalle sonorità degli anni ’50 fino agli anni ’80. La sua passione per la musica si è unita alla sua creatività visiva, dando vita a un progetto unico e coinvolgente.</p>
    </div>
  );
  const workImages = [
    require("../1.jpeg"),
    require("../2.jpeg"),
    require("../3.jpeg"),
    require("../6.jpeg"),
    require("../7.jpeg"),
    require("../8.jpeg"),
    require("../5.jpeg"),
    require("../4.jpeg"),
    require("../10.jpeg"),
    require("../cav.jpeg"),
    require("../reg.jpeg"),
    require("../wen.jpeg"),
  ];

  return (
    <div id="chicco" className="max-w-screen-md sm:mx-auto mx-2 rounded-lg mt-20 p-5 shadow-stone-700 shadow-sm boxart">
      <div className="mb-8">
        <img
          className="rounded-full mx-auto h-52 w-52 object-cover mb-4 shadow-md shadow-teal-600 grayscale hover:scale-105 duration-300"
          src={imageUrl}
          alt={name}
        />
        <h2 className="md:text-5xl text-3xl font-bold text-center mb-2 text-teal-500">{name}</h2>
        <p className="par text-white text-md text-left m-5">{bio}</p>
      </div>
      <div className="flex justify-center mt-4">
        <a className="mx-4" href="https://www.youtube.com/@angelcrybaby81" target="_blank">
          <FontAwesomeIcon icon={faYoutube} size="2x" className="text-teal-500 hover:scale-110" />
        </a>
        <a className="mx-4" href="https://www.facebook.com/ChiccoMarvelousBoy" target="_blank">
          <FontAwesomeIcon icon={faFacebook} size="2x" className="text-teal-500 hover:scale-110" />
        </a>
        <a className="mx-4" href="https://www.instagram.com/alessandrodambrosio81it/" target="_blank">
          <FontAwesomeIcon icon={faInstagram} size="2x" className="text-teal-500 hover:scale-110" />
        </a>
      </div>
      <div className="mt-8 text-center">
        <h1 className="text-4xl font-bold bg-gradient-to-r from-white via-teal-500 to-amber-500 text-transparent bg-clip-text mb-5">Alex and The Motown band:</h1>
        <a className="mx-4" href="https://www.facebook.com/AlexandtheMotownBand/" target="_blank">
          <FontAwesomeIcon icon={faFacebook} size="2x" className="text-amber-500 hover:scale-110" />
        </a>
        <a className="mx-4" href="https://www.instagram.com/alexandthemotownband/" target="_blank">
          <FontAwesomeIcon icon={faInstagram} size="2x" className="text-amber-500 hover:scale-110" />
        </a>
      </div>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-2 lg:grid-cols-3 m-5"> {/* utilizzo di grid-cols-1 per avere una colonna su mobile */}
        {workImages.map((imageUrl, index) => (
          <div key={index}>
            <img
              className="w-full h-full object-cover rounded-md shadow-md shadow-teal-500"
              src={imageUrl}
              alt={`Work ${index}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ArtisanHorizontal;