import React from 'react';

function DescriptionSection() {
  return (
    <section className="mt-16 mx-5 bg-gradient-to-r from-black via-teal-900 to-teal-500 text-white shadow-md shadow-teal-500 opacity-90 rounded-md">
      <div className="max-w-6xl mx-auto py-20 px-4 sm:px-6 lg:px-8">
      <h2 className="text-3xl mb-8 text-center font-bold bg-gradient-to-r from-white via-teal-200 to-amber-500 text-transparent bg-clip-text">
          Benvenuti in un mondo incantato dove tutto è possibile...
        </h2>
        <p className="par text-md mb-12">
      Là dove la magia e l'arte si incontrano per creare preziosi e melodie mozzafiato, si trovano un gioielliere e un cantante/disegnatore/ballerino che hanno lasciato il posto fisso per inseguire i propri sogni. Sì, avete letto bene: hanno scelto di giocare la loro carta vincente e rischiare tutto, persino la stabilità economica.
    </p>
    <p className="par text-md mb-12">
      La natura, la bellezza e la fantasia sono solo alcune delle fonti di ispirazione che li guidano in ogni progetto, spingendoli a superare i limiti della creatività.
    </p>
    <p className="par text-md mb-12">
      Scopri il loro portfolio e immergiti in un ambiente fiabesco, dove i sogni diventano realtà! Magari, chissà, potresti trovare l'ispirazione per fare il grande salto e seguire anche tu il tuo cuore creativo. Dopotutto, il mondo è cambiato, e oggi più che mai è importante rischiare per i propri sogni.
    </p>
    <p className="par text-md font-medium">
      Quindi non pensarci due volte: lasciati trasportare dal loro mondo, e scopri come la forza dell'arte può cambiare la tua vita.
    </p>
    <p className="par text-md font-medium">
      E non dimenticare di venire a trovarli sui social per scoprire di più!
    </p>
      </div>
    </section>
  );
}

export default DescriptionSection;