import './App.css';
import Nav from './components/nav';
import Card from './components/pagina1';
import image from "./enchgiallo.png";
import Artisan from './components/nico';
import workImages from './components/nico';
import ArtisanHorizontal from './components/chicco';
import Footer from './components/footer'


export default function App() {
  return (
    <>

      <Nav />
      <div className=''>

        <Card
          title="Nicola & Alessandro"
          imageUrl={image}
        />
        <Artisan
          name="Nicola Putzolu"
          bio="Nato ad Oristano nel 1990 sotto il segno dell'Ariete, si è sempre dimostrato un bimbo pacato e preciso.
        Cresciuto nell’ambito artistico, sviluppa l’amore per la manualità e la fotografia. Sin da piccolo creava piccoli bracciali, con conchiglie, pietre o semplicemente degli oggettini d’arredo. Dal padre ha appreso l’arte dell’intaglio e il fai da te, oltre alla passione per la musica (il padre faceva piano bar insieme alla mamma). Da sempre amante della fotografia, ha fatto diversi servizi fotografici per eventi musicali e non.
        La sua passione pian piano ha preso piede e nel suo tempo libero ha affinato la manualità nel creare piccoli gioielli con metalli, minerali e pietre preziose.
        Dopo aver acquisito le competenze tecniche su creazione e intaglio si è buttato a capofitto nelle gioielleria (studiando su libri e praticando tanto in casa, creandosi un suo piccolo laboratorio), e dopo un corso di formazione ha tramutato la sua passione in lavoro, attualmente è un tecnico di laboratorio presso uno dei più grandi marchi del settore della gioielleria."
          imageUrl={require("./nico.jpg")}
          workImages={workImages}
        />
        <ArtisanHorizontal
          name="Alessandro D'Ambrosio"
          bio="Nato a Torino nel 1981, segno Bilancia, segno dell’amore e dell’arte. Sin da piccolo ha dimostrato amore per il ballo, il canto e soprattutto per il disegno (arte passata nel sangue dal padre pittore, batterista e appassionato di Beatles). Da sempre un bimbo solare ed estroverso, si dimostra da subito uno showman.
        Gia all’asilo mostrava una spiccata capacità nell’illustrazione. Crescendo ha sviluppato un amore per l’arte grafica giapponese (manga). Ha creato diverse strip comics e fumetti che raccontavano fatti accaduti nella sua vita.
        Diplomatosi poi in grafica pubblicitaria, ha sviluppato una propensione per la creazione grafica di libri fotografici (cantanti che ama, telefilm o semplicemente foto di vacanze), editing video (concerti, progetti teatrali, matrimoni) cover per cd musicali, dvd, Blu-ray per la sua collezione personale di film/progetti musicali fuori commercio;
        All’attivo, sul fronte musicale, ha un progetto chiamato “THE SOUND OF MOTOWN”, col suo gruppo chiamato “Alex and The Motown band”, con una scaletta di 30 canzoni che vanno dalle sonorità degli anni 50 fino agli anni 80."
          imageUrl={require("./ale.jpg")}
          workImages={workImages}
        />



      </div>

      <Footer />
    </>
  )
}
