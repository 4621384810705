import React, { useState, useEffect } from "react";
import FontFaceObserver from "fontfaceobserver";
import "../card.css";
import DescriptionSection from "./decription";

function Card(props) {
  const { title, imageUrl } = props;

  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    const font = new FontFaceObserver("Reenie Beanie");
    font.load().then(() => {
      setFontLoaded(true);
    });
  }, []);

  return (
    <>
      <div className="pagina1 overflow-hidden flex justify-center p-10 opacity-90 overlay">
        <div className="relative logo-container">
          <img
            className="rounded-full shadow-md shadow-teal-600 logo"
            src={imageUrl}
            alt={title}
          />
        </div>
      </div>
      <div className="flex justify-center items-center heading-container">
        <h1
          className="text-4xl"
          style={{
            color: "#fff",
            textAlign: "center",
            textShadow:
              "0 0 5px #fff, 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #ff9900, 0 0 40px #ff9900, 0 0 50px #ff9900, 0 0 75px #ff7f50",
            fontFamily: "Reenie Beanie",
            visibility: fontLoaded ? "visible" : "hidden"
          }}
        >
          {title}
        </h1>
      </div>
      <DescriptionSection />
    </>
  );
}

export default Card;