import logo from '../nuovologo.png'

function Footer() {
  return (
    <div className="bg-stone-900 h-auto md:h-92 mt-10">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="flex flex-col justify-center md:justify-start mt-8 md:mt-20 p-2 md:p-10 bg-stone-900 w-full md:w-auto opacity-80">
          <img className="w-24 mx-auto md:mx-0" src={logo} alt="Logo" />
          <div className="text-white text-center md:text-left md:text-3xl text-2xl my-5 md:my-0">
            <a href="#nico" className="hover:text-teal-600 px-3 py-2 rounded-md">
              Nicola
            </a>
            
            <a href="#chicco" className="hover:text-teal-600 px-3 py-2 rounded-md">
              Alessandro
            </a>
          </div>
        </div>
        <div className="text-amber-500 bg-stone-900 text-lg mt-5 p-5 md:p-10 flex items-center justify-center md:justify-end">
          Developed by
          <a href="https://www.indiefetto.it/" className="px-3 py-2 rounded-md text-2xl" target="_blank" rel="noopener noreferrer">
            Alessia Gaudioso
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;