import React, { useState } from "react";
import logo from '../nuovologo.png'

function Nav() {
    const [open, setOpen] = useState(false);

    return (
        <nav className="bg-stone-900 shadow-md shadow-teal-600">
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 flex justify-center">
                <div className="relative flex items-center justify-between h-26">
                    <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                        <div className="">
                            <a href="/" className="flex items-center">
                                <img
                                    className="block h-16 sm:h-20 w-auto"
                                    src={logo}
                                    alt="Logo"
                                />
                                <h1 className="bg-gradient-to-r from-white via-teal-200 to-amber-500 text-transparent bg-clip-text ml-2 text-3xl font-bold">
                                    Enchanted Team
                                </h1>
                            </a>
                        </div>
                        <div className="hidden sm:block sm:ml-10 mt-5">
                            <div className="flex space-x-4">
                                <a
                                    href="#nico"
                                    className="text-white hover:text-teal-600 px-3 py-2 rounded-md font-medium text-xl"
                                >
                                    Nicola
                                </a>
                                <a
                                    href="#chicco"
                                    className="text-white hover:text-teal-600 px-3 py-2 rounded-md font-medium text-xl"
                                >
                                    Alessandro
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="-mr-2 flex items-center sm:hidden">
                        <button
                            onClick={() => setOpen(!open)}
                            type="button"
                            className="inline-flex items-center justify-center p-2 rounded-md text-white focus:outline-none "
                            aria-controls="mobile-menu"
                            aria-expanded="false"
                        >
                            <span className="sr-only">Open main menu</span>
                            {open ? (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                </svg>


                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                </svg>
                            )}
                        </button>
                    </div>
                </div>
            </div>
            {open && (
                <div className="sm:hidden" id="mobile-menu">
                    <div className="px-2 pt-2 pb-3 space-y-1">
                        <a
                            href="#nico"
                            className="text-white block hover:bg-gold px-3 py-2 rounded-md text-xl"
                        >
                            Nicola
                        </a>
                        <a
                            href="#chicco"
                            className="text-white block hover:bg-gold px-3 py-2 rounded-md text-xl"
                        >
                            Alessandro
                        </a>
                    </div>
                </div>

            )}
        </nav>

    );
}

export default Nav;