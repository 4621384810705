import React from "react";
import "../nico.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";

function Artisan(props) {
  const { name, imageUrl } = props; // nome, bio, immagine e immagini dei lavori
  const bio = (
    <div>
      <p>
        Nato ad Oristano nel 1990 sotto il segno dell'Ariete, si è sempre dimostrato un bimbo pacato e preciso.
      </p>
      <p>
      Cresciuto in un ambiente artistico, ha sviluppato l'amore per la manualità e la fotografia, e fin da piccolo creava piccoli braccialetti con conchiglie, pietre e altri materiali che lo ispiravano. Ha appreso l'arte dell'intaglio e del fai da te dal padre, insieme alla passione per la musica (il piano bar era una presenza costante nelle sue serate familiari).
        <p>La passione per la fotografia l'ha accompagnato per tutta la vita, e ha realizzato diversi servizi fotografici per eventi di ogni tipo.</p>
        <p>Ma è stato l'amore per i gioielli a spingerlo a dare il meglio di sé. Dopo aver affinato la manualità nella creazione di piccoli gioielli con metalli, minerali e pietre preziose nei suoi momenti liberi, ha deciso di dedicarsi completamente al mondo della gioielleria. Ha studiato su libri e ha praticato molto a casa, dove ha creato un piccolo laboratorio. Grazie alla determinazione e alla voglia di imparare, ha seguito un corso di formazione che gli ha permesso di trasformare la sua grande passione in un lavoro. Attualmente è un tecnico di laboratorio presso uno dei più grandi marchi del settore della gioielleria.</p>
      </p>
      
    </div>
  );

  const workImages = [
    require("../gufo.jpg"),
    require("../cion.jpg"),
    require("../pietra.jpg"),
    require("../autumn.jpg"),
    require("../aut.jpg"),
    require("../pink.jpg"),
  ];

  return (

    <div id="nico" className="max-w-screen-md sm:mx-auto mx-2 mt-20 p-2 shadow-stone-700 shadow-sm rounded-lg boxart">
      <div className="mb-8">
        <img
          className="rounded-full mx-auto h-52 w-52 object-cover mb-4 shadow-md shadow-teal-600 grayscale hover:scale-105 duration-300"
          src={imageUrl}
          alt={name}
        />
        <h2 className="md:text-5xl text-3xl font-bold text-center mb-2 text-teal-500">{name}</h2>
        <p className="par text-white text-md text-left m-5">{bio}</p>
      </div>
      <div className="flex justify-center mt-4">
        <a className="mx-4" href="https://www.facebook.com/putzolu" target="_blank">
          <FontAwesomeIcon icon={faFacebook} size="2x" className="text-teal-500 hover:scale-110" />
        </a>
        <a className="mx-4" href="https://www.instagram.com/nicola_putzolu_gioielli/" target="_blank">
          <FontAwesomeIcon icon={faInstagram} size="2x" className="text-teal-500 hover:scale-110" />
        </a>
      </div>
      <div className="grid m-5 grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {workImages.map((imageUrl, index) => (
          <div key={index}>
            <img
              className="w-full object-cover rounded-md shadow-md shadow-teal-600"
              src={imageUrl}
              alt={`Work ${index}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Artisan;